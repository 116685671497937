<template>
    <div
        class="memod-card"
        :class="{
            'is-pin' : memod.is_pin,
            'selected-memo' : selectedMemo,
            'is-serialized' : isSerialized,
        }"
    >
        <div v-if="memod.is_reported" class="is-reported">
            <svg
                width="15"
                height="20"
                viewBox="0 0 15 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.933561 1.75971V10.3395C2.49368 10.0413 5.14148 9.64573 7.57059 10.4554C8.53689 10.7775 9.77532 10.8516 10.9579 10.8019C11.9427 10.7605 12.8602 10.6346 13.5002 10.5131V1.88409C12.7063 2.07002 11.745 2.26476 10.7708 2.37286C9.5749 2.50556 8.30685 2.5144 7.29653 2.17762C5.38121 1.53918 3.49667 1.63552 1.89948 1.71716C1.56404 1.73431 1.24127 1.7508 0.933561 1.75971ZM0.933561 11.2225C2.4563 10.9258 5.00598 10.5141 7.29653 11.2776C8.41023 11.6489 9.7718 11.7192 10.9943 11.6678C12.2235 11.6161 13.3539 11.4392 14.0276 11.2895C14.2258 11.2455 14.3669 11.0696 14.3669 10.8665V1.33319C14.3669 1.19976 14.3054 1.07376 14.2002 0.991638C14.0951 0.909518 13.9579 0.880435 13.8285 0.912799C12.9687 1.12773 11.8252 1.38388 10.6752 1.51148C9.51403 1.64032 8.39875 1.63148 7.57059 1.35543C5.48165 0.659117 3.36079 0.769739 1.7496 0.853777C1.28681 0.877915 0.866079 0.89986 0.500228 0.89986C0.260904 0.89986 0.0668945 1.09387 0.0668945 1.33319V18.6665C0.0668945 18.9058 0.260904 19.0999 0.500228 19.0999C0.739551 19.0999 0.933561 18.9058 0.933561 18.6665V11.2225Z"
                    fill="white"
                />
            </svg>
            <span>Reported</span>
        </div>
        <div v-if="selectMode" class="select-mode-overlay" @click="$emit('selected-memo', memod)" />
        <div class="card-container">
            <div v-if="!isDraft" class="more-options">
                <v-popover
                    v-if="isMemodAuthor && $route.name != 'web-app-board-view'"
                >
                    <div class="tooltip-target b3">
                        <img src="@assets/img/icons/three-dots.svg" alt="More options">
                    </div>
                    <template slot="popover">
                        <div class="popover-options">
                            <router-link :to="{ name: 'web-app-edit', params: { slug: memod.slug, id: memod.id } }" class="popover-option">
                                <span>Edit</span>
                                <img src="@assets/img/icons/edit.svg" alt="Edit memo">
                            </router-link>
                            <div class="popover-option">
                                <span>Delete</span>
                                <delete-button :memod="memod" />
                            </div>
                        </div>
                    </template>
                </v-popover>
                <v-popover
                    v-if="!isMemodAuthor && $route.name != 'web-app-board-view'"
                >
                    <div class="tooltip-target b3">
                        <img src="@assets/img/icons/three-dots.svg" alt="More options">
                    </div>
                    <template slot="popover">
                        <div class="popover-options">
                            <report-button :entity-id="Number(memod.id)" entity-name="memo" class="popover-option" />
                        </div>
                    </template>
                </v-popover>
                <v-popover
                    v-if="$route.name == 'web-app-board-view'"
                >
                    <div class="tooltip-target b3">
                        <img src="@assets/img/icons/three-dots.svg" alt="More options">
                    </div>
                    <template slot="popover">
                        <div class="popover-options">
                            <div class="popover-option">
                                <user-follow :user="memod.writer" @toggled-follow="(is_following) => $emit('toggled-follow', is_following)" />
                            </div>
                            <div
                                v-if="isCurrentRouteBoardAuthor"
                                v-close-popover
                                class="popover-option"
                                @click="toggleMemoPin"
                            >
                                <span>{{ memod.is_pin ? "Unpin" : "Pin" }} Memo</span>
                                <img
                                    :src="require(`@assets/img/icons/${memod.is_pin ? '' : 'un'}pinned.svg`)"
                                    alt="Pin memo"
                                >
                            </div>
                            <div v-if="isCurrentRouteBoardAuthor" class="popover-option" @click="removeMemoFromBoard">
                                <span>Remove Memo</span>
                                <img
                                    src="@assets/img/icons/trash-can.svg"
                                    class="delete"
                                    alt="delete"
                                >
                            </div>
                        </div>
                    </template>
                </v-popover>
            </div>
            <span v-if="isSerialized" class="multipart-label">Multipart</span>
            <router-link :to="{ name: isDraft ? 'web-app-edit' : (isSerialized ? 'memod-reader-serialized-part' : 'memod-reader'), params: { writer: `${memod.writer ? memod.writer.displayname : memod.user.displayname}`, slug: memod.slug, id: memod.id, partNumber: 1 } }" @click.native="toggleDraftsList">
                <img
                    v-if="coverImage"
                    :class="`image ${coverImage.orientation}`"
                    :srcset="`${coverImage.x1}, ${coverImage.x2} 2x, ${coverImage.x3} 3x`"
                    loading="lazy"
                    :alt="memod.title"
                    :style="`height: ${coverImage.height / (coverImage.width / 276) }px`"
                >
                <img
                    v-else
                    class="image landscape"
                    src="@assets/img/memo-placeholder.jpg"
                    loading="lazy"
                    :alt="memod.title"
                    :style="`height: 250px`"
                >
            </router-link>
            <router-link
                v-if="memod.writer"
                :to="{ name: 'web-app-profile', params: { displayName: `${memod.writer.displayname}` } }"
                class="author"
            >
                <user-picture :picture="memod.writer.photo.url" />
                <user-nickname :user-nickname="memod.writer.displayname" />
            </router-link>
            <router-link :to="{ name: isDraft ? 'web-app-edit' : (isSerialized ? 'memod-reader-serialized-part' : 'memod-reader'), params: { writer: `${memod.writer ? memod.writer.displayname : memod.user.displayname}`, slug: memod.slug, id: memod.id, partNumber: 1 } }" @click.native="toggleDraftsList">
                <h3>{{ memod.title }}</h3>
            </router-link>
            <actions-buttons
                :memod="memod"
                :is-draft="isDraft"
                :is-serialized="isSerialized"
                @toggled-like="(data) => $emit('toggled-like', data)"
                @toggled-save="(is_saved) => $emit('toggled-save', is_saved)"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
    name: "MemodCard",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserNickname: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-nickname"),
        UserFollow: () => import(/* webpackChunkName: "user-follow" */ "@c/atoms/user-follow"),
        ActionsButtons: () => import(/* webpackChunkName: "actions-buttons" */ "@c/molecules/actions-buttons"),
        DeleteButton: () => import(/* webpackChunkName: "delete-button" */ "@c/atoms/delete-button"),
        ReportButton: () => import(/* webpackChunkName: "report-button" */ "@c/molecules/report/report-button")
    },
    props: {
        selectMode: {
            type: Boolean,
            default: false
        },
        selectedMemo: {
            type: Boolean,
            default: false
        },
        memod: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            imageResizerUrl: process.env.VUE_APP_IMAGE_RESIZER_URL
        };
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id,
            currentBoard: state => state.Boards.currentBoard
        }),
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        isDraft() {
            return !Number(this.memod.is_published);
        },
        isSerialized() {
            return Boolean(this.memod.type.name === "Serialized" && (this.memod.threads ? this.memod.threads.length : 0));
        },
        coverImage() {
            const coverImage = this.memod.files.find(file => file.field_name == "cover-image");

            if (!coverImage) {
                return false;
            }

            const imagesSizes = {
                x1: "",
                x2: "",
                x3: ""
            }

            Object.keys(imagesSizes).forEach(key => {
                imagesSizes[key] = `${this.imageResizerUrl}/${coverImage ? coverImage.attributes.unique_name : ""}?w=${276 * key.charAt(key.length - 1)}&fit=outside`
            })

            return {
                ...imagesSizes,
                orientation: coverImage.attributes.orientation,
                width: Number(coverImage.attributes.width),
                height: Number(coverImage.attributes.height)
            };
        },
        isMemodAuthor() {
            if (this.memod.writer) {
                return Boolean(Number(this.memod.writer.id) === Number(this.userId));
            }
            return false;
        },
        isCurrentRouteBoardAuthor() {
            if (this.$route.name == "web-app-board-view" && this.currentBoard.id) {
                return Boolean(Number(this.currentBoard.user.id) === Number(this.userId));
            } else {
                return false;
            }
        }
    },
    methods: {
        toggleDraftsList() {
            this.$store.dispatch("Application/toggleDraftsList", false);
        },
        toggleMemoPin() {
            this.$store.dispatch("Boards/toggleMemoPin", { memoId: this.memod.id, boardId : this.$route.params.id }).then(() => {
                window.location.reload();
            });
        },
        removeMemoFromBoard() {
            this.$store.dispatch("Boards/toggleMemoToBoard", { memoId: this.memod.id, boardId : this.$route.params.id }).then(() => {
                window.location.reload();
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.memod-card {
    width: 276px;
    display: block;

    .author {
        display: flex !important;
        align-items: center;
        color: white;
        text-decoration: none;
        height: 45px;
        padding: 0 12px;

        /deep/ .user-picture {
            figure {
                width: 27px;
                height: 27px;
            }

            margin-right: 5px;
        }

        /deep/ .user-nickname {
            color: #D7D9DB;
        }
    }

    .more-options {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        background-color: rgba(15, 15, 16, 0.7);
        border-radius: 100%;

        .tooltip-target {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
            }
        }
    }

    .multipart-label {
        background-color: rgba(15, 15, 16, 0.7);
        position: absolute;
        left: 10px;
        top: 10px;
        padding: 3px 8px;
        border-radius: 14px;
    }

    .card-container {
        background-color: #1C1C1E;
        border-radius: 10px;
        border: 1px solid rgba(84, 84, 88, 0.65);
        position: relative;

        a {
            color: white;
            text-decoration: none;
            display: block;

            .image {
                width: 100%;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-image: url("~@assets/img/icons/progress-circle-master.svg");
                background-repeat: no-repeat;
                background-position: center;
                object-fit: cover;
            }

            h3 {
                color: #D7D9DB;
                padding: 12px;
                padding-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .actions-buttons {
            padding: 6px 12px;
            padding-top: 0;
            justify-content: space-between;
            /* hardcoded height to help masonry layout and avoid overlapping */
            height: 39px;
        }
    }

    &.is-pin {
        .card-container {
            border: 1px solid rgba(84, 84, 88, 0.65);
        }
    }

    &.selected-memo {
        .card-container {
            border: 1px solid $primary-color;
        }
    }

    &.is-serialized {
        .card-container {
            &::after {
                content: "";
                height: 16px;
                position: absolute;
                border: 1px;
                border-style: solid;
                border-top: 0;
                border-color: rgba(84, 84, 88, 0.65);
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                width: 272px;
                left: 50%;
                transform: translateX(-50%);
                bottom: -13px;
            }
        }
    }

    .is-reported {
        content: "";
        position: absolute;
        backdrop-filter: blur(6px);
        z-index: 1;
        top: 1px;
        left: 1px;
        right: 1px;
        bottom: 1px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span {
            margin-top: 20px;
            display: block;
            font-size: 16px;
        }
    }

    .select-mode-overlay {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }
}
</style>
